export const main = {
	display: "flex",
	flexDirection: "column",
};

export const wrapper = {
	display: "flex",
};

export const content = {
	backgroundColor: "background",
	position: "relative",
	maxWidth: 960,
	px: 3,
};
